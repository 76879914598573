<template>
    <div class="content">
        <header class="section centered-content-75 is-inline-block my-6">
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("navigation.features") }}</h1>
            <div class="columns">
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/arrow_step1.png" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.scan_it") }}</h2>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/arrow_step2.png" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.retrieve_it") }}</h2>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/arrow_step3.png" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.process_it") }}</h2>
                    </div>
                </div>
            </div>
        </header>
        <main class="section centered-content-75 is-inline-block my-6">
            <div class="columns is-variable is-6 my-3">
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/swiss_flag.png" width="64" height="64" alt="swiss_flag" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile" v-html="$t('message.features.servers')"></h2>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.servers_desc1") }}
                        </p>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.servers_desc2") }}
                        </p>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/lock2.png" alt="lock" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.privacy") }}</h2>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.privacy_desc1") }}
                        </p>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.privacy_desc2") }}
                        </p>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/qr2.png" alt="qr" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.qr") }}</h2>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.qr_desc1") }}
                        </p>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.qr_desc2") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="columns is-variable is-6 my-3">
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/backup2.png" alt="backup" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.backup") }}</h2>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.backup_desc1") }}
                        </p>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/key2.png" alt="key" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">{{ $t("message.features.crypto") }}</h2>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.crypto_desc1") }}
                        </p>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.crypto_desc2") }}
                        </p>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="inner-content">
                        <img src="../../assets/images/features/robot2.png" alt="robot" />
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white is-size-4 is-size-5-mobile">
                            <span class="tag is-seenjab-green mr-2">{{ $t("message.common.soon") }}</span>
                            {{ $t("message.features.ai") }}
                        </h2>
                        <p class="has-text-justified is-size-6-desktop is-size-7-tablet">
                            {{ $t("message.features.ai_desc1") }}
                        </p>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
/**
 * Features view
 * Display the Features page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'Features',
    metaInfo: function() {
        return {
            title: `${this.$t("message.features.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.features.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.features.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.features.meta.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.features {
    width: 80%;
    margin: auto;
}

.features-list {
    text-align: left;
}

@media(min-width: $desktop-landscape) {
    .features-list {
        margin: 0 32px;
    }
}

@media(max-width: $tablet-landscape) {
    .features {
        width: 100%;
    }
}
</style>